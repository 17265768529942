@import "./pages/Home/style";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
a {
    text-decoration: none !important;
}

html,
body,
#root,
#root > div {
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 500;


}