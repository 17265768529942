.light {
    background-color: #f7f9ff;
}

ul.dropdown-menu {
    background-color: transparent;
    border: 0px;
    flex-direction: column;
}

ul li {
    margin-bottom: 12px;
}

.nav-button-style-site {
    list-style-type: none;
    background-color: #edf2ff;
    padding: 10px 20px 10px 20px;
    margin: 5px;
    transition: 0.3s all;
    display: inline;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.3vh;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 8px;
}

.nav-button-style-site:hover {
    transition: 0.3s all;
    color: #fff;
    background-color: #2e3785;
    font-weight: 500;
    letter-spacing: 1.2px;
}

@media screen and (max-width: 1260px) {
    .nav-button-style-site {
        display: none;
    }
   
}

.nav-button-style-site-trabalhe-conosco {
    list-style-type: none;
    background-color: #30348c;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    transition: 0.3s all;
    display: inline;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-size: 1.3vh;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 8px;
}

.nav-button-style-site-trabalhe-conosco:hover {
    transition: 0.3s all;
    color: #fff;
    background-color: #2e3785;
    font-weight: 500;
    letter-spacing: 1.2px;
}

@media screen and (max-width: 1260px) {
    .nav-button-style-site-trabalhe-conosco {
        display: none;
    }
}


.image_logo {
    @media screen and (max-width: 440px) {
        max-height: 12.5vh;
        height: 10.0vh;
        width: 80vw;
        padding: 5px 5px 0 5px;
    }
    height: 80px;
    padding: 5px 0 0 5px;
}