
.heading-secondary {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  .heading-secondary:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2); }

    .link {
      position: relative;
      text-decoration: none;
      color: #3c528f; /* Cor do link */
      transition: color 0.2s; /* Transição suave da cor do texto */
    }
    
    /* Elemento ::after para a linha */
    .link::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px; /* Altura da linha */
      background-color: #30348c; /* Cor da linha */
      bottom: -4px; /* Distância da linha em relação ao texto */
      left: 0;
      transform: scaleX(0); /* Inicialmente, a linha não é visível */
      transform-origin: center;
      transition: transform 0.2s ease-in-out;
    }
    
    /* Animação da linha ao passar o mouse */
    .link:hover::after {
      transform: scaleX(1); /* Tornar a linha visível com largura completa */
    }
    
    /* Alterar a cor do texto ao passar o mouse */
    .link:hover {
      color: #30348c; /* Cor do texto ao passar o mouse */
    }